import request from '@/utils/request'

const tradeProjectCostApi = {
  trade_project_cost_list: '/trade/project_cost/',
  trade_project_cost_create: '/trade/project_cost/',
  trade_project_cost_update: '/trade/project_cost/',
  trade_project_cost_delete: '/trade/project_cost/',
  trade_project_cost_archived: '/trade/project_cost_archived/'
}

/**
 * 列表
 */
export function project_cost_list (parameter) {
  return request({
    url: tradeProjectCostApi.trade_project_cost_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function project_cost_create (parameter) {
  return request({
    url: tradeProjectCostApi.trade_project_cost_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function project_cost_update (parameter, project_cost_id) {
  return request({
    url: tradeProjectCostApi.trade_project_cost_update + project_cost_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function project_cost_delete (project_cost_id) {
  return request({
    url: tradeProjectCostApi.trade_project_cost_delete + project_cost_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function project_cost_archived (parameter) {
  return request({
    url: tradeProjectCostApi.trade_project_cost_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
